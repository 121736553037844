.main-home-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.alt-main-home-container {
    min-height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.card-home-container {
    height: 45vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100vw;
}

.picture-section {
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 70vw;
}

.img-container {
    width: 45%;
}
.elevator-pitch-img {
    max-width: 100%;
    max-height: 100%;
}

.centering-below {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.logo-with-contact-btn {
    width: 10%;
}

.words-next-to-logo {
    width: 30%;
}


.size-1 {
    height: 10vh;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;

}

.size-2 {
    height: 35vh;
    width: 90vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.small-bullet-point-section {
    width: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.small-bullet-point-black {
    height: 25px;
    width: 25px;
    background-color: black;
    border-radius: 50%;
    position: absolute;
}

.small-bullet-point-white {
    height: 5px;
    width: 5px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
}

.double-word-section {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.smaller-words {
    font-size: 1vw;
    font-weight: 800;
}

.bigger-words {
    font-size: 2vw;
    font-weight: 600;
}

.card {
    width: 25vw;
    height: 30vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 10px 10px 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.random-box {
    height: 40px;
    width: 40px;
    background-color: red;
}

.random-box-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7.5vh;
    width: 25vw;
}

.under-subject {
    overflow: hidden;
    height: 12.5vh;
    text-align: center;
    padding: 5%;
}

.subject {
    height: 5vh;
}


.dot-two-lines {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 15vh;
}

.words-small {
    font-size: 2vw;
    height: 3vh;
}

.words-big {
    height: 5vh;
    font-size: 3vw;
}

.actual-img {
    height: 75%;
    width: 75%;
}

.founder-showcase {
    display: flex;
    padding-bottom: 5vh;
}

.founders-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    flex-direction: column;
}

.img-container-founders {
    width: 35vw;
}

.text-with-title {
    width: 35vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
}

.title-text {
    font-size: 2vw;
    display: flex;
    justify-content: flex-start;
}

.under-title-text {
    font-size: 1vw;
}