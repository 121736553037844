.card-projects {
    padding: 2%;
    background-color: #ffffff;
    box-shadow: 5px 5px 5px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    height: 40vh;
}

.actual-img-team {
    height: 100%;
    width: 100%;
}

.img-container {
    display: flex;
    height: 40%;
}

.name-timeline {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 20%;
}

.dot-container {
    height: 20%;
}

.card-container-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2vw;
    grid-auto-rows: 100px;
    width: 80vw;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.big-words-project {
    font-size: 3vw;
    font-weight: 700;
    padding-bottom: 1%;
}