.navbar-container { 
    max-height: 7.5vh;
    position: sticky;
    top: 0px;
    width: 95vw;
    z-index: 10;
}

.actual-navbar {
    display: flex;
    align-items: center;
    max-height: 7.5vh;
    min-height: 5vh;
    width: 35vw;
    background-color: transparent;
    justify-content: space-evenly;
}

